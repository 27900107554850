import React from "react";
import '../assets/css/Dashboard-Style.css';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";

class District extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      inputValue: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(event) {
    this.props.onChangeDistrict(event.id);
    this.setState({id: event.id});
  }

  handleChange(event) {
    this.setState({inputValue: event});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDistrict !== this.props.selectedDistrict) {
      this.setState({inputValue: { "id": '', "name": "" }, ...this.state});
    }
    if(prevProps.clear !== this.props.clear) {
      this.setState({...this.state, inputValue: null});
      this.props.onChangeDistrict(null, null);
    }
  }

  render() {
    const fieldProperties = this.props.districtFields.district;
    return(
      <div className={ `col-md-${ fieldProperties.colmd } col-lg-${ fieldProperties.collg } col-${ fieldProperties.col } pt-3` }>
        <div className='form-group text-start my-auto'>
        { fieldProperties.labelStatus ? <label>{ fieldProperties.label }</label> : '' }
          <Combobox data={this.props.districtList} options={this.props.districtList} placeholder={ fieldProperties.placeholder } dataKey='id' textField='name' value={ this.state.inputValue } onSelect={this.handleSelect} onChange={this.handleChange} disabled={this.props.disabled ? true :false} />
        </div>
      </div>
    );
  }
}

export default District;