import { useState,useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { passwordReset, tokenValidationForReset } from '../../services/Voters';
import { useSearchParams } from "react-router-dom";
import { RotatingLines } from  'react-loader-spinner';
import {Operations} from '../toast/operations';
import { useNavigate} from "react-router-dom";	
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";




function PasswordResetConfirm(props){

    const [newPassword,setNewPassword] = useState();
    const [isvalid, setIsvalid] = useState(false);
    const [token, setToken] = useState();
    const [wrongToken, setWrongToken] = useState(false);
	const [errorLog, setErrorLog] = useState([]);
	const [isVisible, setIsvisible] = useState('password');
	let navigate = useNavigate();


    const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() =>{

      let tokenObject ={'token':searchParams.get("token")}
      tokenValidationForReset(tokenObject).then((postData) => {
            
            if(postData?.status == 200){
                setToken(searchParams.get("token"));
                setIsvalid(true);
            }
        }).catch((err) => {
            setWrongToken(true);
                setIsvalid(true);
        })

	},[]);

	const handleSeePassword = (event) =>{
		if(isVisible == 'password'){
			setIsvisible('text');
		}
		else{
			setIsvisible('password');
		}
	}

    const newPasswordchange = (event) =>{
		setErrorLog('');
		const validatePassword = (password) => {
			return String(password)
			  .match(
			  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
			  );
			};

			if(validatePassword(event.target.value)) {
			  	setNewPassword(event.target.value);
				
			}
			else {
				setNewPassword('');
				setErrorLog(['Please enter valid password. EX: Abcd@1234']);
			}
	}

    const formSubmission = (event) =>{
				let passwordObject = {'password': newPassword, 'token': searchParams.get("token")};
				if(newPassword){
					passwordReset(passwordObject).then((postData) => {
						navigate(`/login?op=${Operations.PASSWORD_RESET}&status=success`);
					}).catch((err) => {
						navigate(`/login?op=${Operations.PASSWORD_RESET}&status=error`);
						setErrorLog(err.response.data.password);
					})
				}
    }

	const handlePwdKeyUp = (event) =>{
		if (event.key === 'Enter') {
		  formSubmission();
		}
	  }

    return(
        <>           
            <div className="row">
			<div className="col-md-6 m-auto mt-5">
				<div className="card">
					<div className="card-header">
						<h3>Password Reset</h3>
					</div>
					<div className="card-body">

                { isvalid != true ? 
                <div className='text-center m-5'>
                <RotatingLines strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true} />
              </div>
                :
                    wrongToken == false ?
                    <>
			      			<InputGroup className="mb-3">
						        <Form.Control
						          placeholder="New Password"
						          onChange={newPasswordchange}
						          type={isVisible}
						          autoComplete="new-password"
								  onKeyUp={handlePwdKeyUp}
						        />
								 <Button variant="outline-secondary" id="button-addon2" onClick={handleSeePassword}><FontAwesomeIcon icon={ faEye } /></Button>
			      			</InputGroup>
			      			<button className="btn btn-success" variant="primary" onClick={formSubmission}>
			        			Change Password
			      			</button>

							<div className="error-area mt-4">
							<span className="text-danger fw-600">{ 
							errorLog != '' ? 
							errorLog.reduce((items, current) => {
							let returnList = []
							returnList.push(
								<li key={current}>{current}</li>
							)	
							return items.concat(returnList);
							}, []) : ''
							}
						</span>
							</div>

      				        </>   : <h2>Token is not Valid</h2> }
					</div>
				</div>
			</div>
		</div>
        </>
    );
}

export default PasswordResetConfirm;