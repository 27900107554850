import React from "react";
import LoginImage from '../../assets/img/60111.jpg';
import { login } from '../../services/Auth';
import { Link } from "react-router-dom";
import StatusToast from "../toast/StatusToast";



class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      error: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePwdKeyUp = this.handlePwdKeyUp.bind(this);
  }


  handlePwdKeyUp(event){
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmit();
    }
  }

  handleName(event) {
    this.setState({error:''});
    this.setState({name: event.target.value});
  }

  handlePassword(event) {
    this.setState({error:''});
    this.setState({password: event.target.value});
  }





  handleSubmit() {
    const params = {
      "username": this.state.name,
      "password": this.state.password
  }
    login(params, this.props.onLogout).then((msg) => {
      this.setState({error: ''});
      this.props.onLogin();
    }).catch((err) => {
      this.setState({error: 'Failed to login. Please try again.'});
    })
  }

  render() {
    return(
      
      // Set margin to 0 for content wrapper in login page.
      <div className="content-wrapper" style={{margin: 0}}>
        <div className='container-fluid'>
		    <StatusToast status='Voter Survey' delayTime="3000"/>
          <div className="login-container">
            <div className="row">
              <div className="col-12 m-auto">
                <div className="card p-2">
                  <div className="card-content">
                    <form action="" >
                      <div className="row">
                        <div className="col-12">
                          <div className="py-3 px-5">
                            <img src={LoginImage} className='rounded mx-auto d-block' alt="" width={100} height={100} />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Username" name="username" id="username" value={this.state.name} onChange={this.handleName} required/>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <input type="password" name="password" id="password" placeholder="Pasword" className="form-control" value={this.state.password} onChange={this.handlePassword} onKeyUp={this.handlePwdKeyUp}/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                            <span className='text-danger fw-600'>{this.state.error}</span>
                        </div>
                        <div className="col-6">
                          <div className="float-right">
                            <input type="button" value='Login' className='btn btn-success' onClick={this.handleSubmit} />
                          </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="float-right">
                              <Link to="/reset">Reset Password</Link>
                            </div>
                          </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;