import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Header-Style.css';
import { NavLink,Link } from 'react-router-dom';
import { logout } from '../services/Auth';
import {getUserProfile} from '../services/Voters';
import userImage from '../assets/img/usericon.png';
import Popup from 'reactjs-popup';


const Header = (props) => {

  const [user,setUser] = useState();

  const [userInfo,setUserInfo] = useState(null);

const [colleps,setColleps] = useState(true);

const checkUserisLoggedin = () =>{

  getUserProfile().then((UserProfile) =>{

	setUserInfo({UserProfile});
  }).catch((error) => {
	console.log(error);
  });
}

  useEffect(() =>{
	checkUserisLoggedin();
  },[]);

  const handleLogout = () => {
	logout();
	props.onLogout();
  }



function getWindowDimensions() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const ratio = (width / height) * 100;
  return { width, height, ratio };
}

const { width } = getWindowDimensions();

 const onBtnClick = () =>{
	setColleps(prevColleps => !prevColleps);
	if (width < 768) {
	  props.onCollepaseClick(false);
	} else {
	  props.onCollepaseClick(false);
	}
  }

  return(
	<>
		<nav className="main-header navbar navbar-expand navbar-white navbar-light sticky-top">
			<ul className="navbar-nav d-flex">
	  			<li className="nav-item">
		 			<a className="nav-link" data-widget="pushmenu" href="#" role="button" onClick={onBtnClick}><i className="fas fa-bars"></i></a>
	  			</li>
			</ul>
	  		<ul className="navbar-nav d-flex px-3 ms-auto">
			  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#latesttagfloatingfeed">
				Tag Feed
			</button>
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="true">
						<img src={userImage} className='rounded mx-auto d-block rounded-circle border border-1 border-dark user-select-all' alt="" width={30} height={30} />
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{left: 'inherit', right: 0 + 'px'}}>
						<div className="upper">
							<img src="https://i.imgur.com/Qtrsrk5.jpg" className="img-fluid" />
						</div>
						<div className="user text-center">
							<div className="" style={{marginTop: -25 + 'px'}}>
								<img src={userImage} className='rounded mx-auto d-block rounded-circle border border-1 border-dark user-select-all' alt="" width={50} height={50} />
							</div>
						</div>
						<div className="mt-1 text-center">
							<h5 className="p-2">
								<Link as={Link} className="text-secondary no-underline" to="/user-profile">{userInfo?.UserProfile?.user?.name ? `${userInfo?.UserProfile?.user?.name}` : `${userInfo?.UserProfile?.user?.mobile_number}`} </Link>
							</h5>
						</div>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item dropdown-footer text-center" onClick={handleLogout}>Logout</a>
					</div>
				</li>
	  		</ul>
  		</nav>
	</>
);
}

export default Header;