import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';
import Chart from "react-apexcharts";

ChartJS.register(ArcElement, Tooltip, Legend);

class DashboardColumnChart extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {};
    }

      render() {
        return (
            <div className="app">
                <div className="row">
                    <div className="mixed-chart">
                        <p className='fs-5 fw-600'>District Wise</p>
                        <Chart options={ this.props.columnData.options } series={ this.props.columnData.series } type="bar" width="100%" />
                    </div>
                </div>
            </div>
        );
      }
}

export default DashboardColumnChart;