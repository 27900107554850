import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import MediumTypes from '../MediumTypes';
import Media from '../Media';
import Topics from '../Topics';
import NewsCategories from '../NewsCategories';
import { newsFilterFields, getAllMediumTypes, getMedia, getTopics, getNewsCategories } from '../../services/Voters';
import District from '../Districts';
import Constituency from '../Constituency';


class TopicSelection extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  error: '',
	  clear: true,
	  fromDate: '',
	  toDate: ''
	};
	this.onChangeMediumType = this.onChangeMediumType.bind(this);
	this.onChangeMedium = this.onChangeMedium.bind(this);
	this.onChangeTopic = this.onChangeTopic.bind(this);
	this.onChangeNewsCategory = this.onChangeNewsCategory.bind(this);
	this.onChangeDistrict = this.onChangeDistrict.bind(this);
	this.onChangeConstituency = this.onChangeConstituency.bind(this);
	this.onChangeFromDate = this.onChangeFromDate.bind(this);
	this.onChangeToDate = this.onChangeToDate.bind(this);
	this.handleReset = this.handleReset.bind(this);
  }

  handleReset() {
	let selectedMediumType = 1;
	getMedia(selectedMediumType).then((media) => {
		if(media && media.length == 0) {
			getTopics(null).then((topics) => {
				this.props.onApplyFilter(selectedMediumType, null, null, null, this.props.mediumTypes, media, topics, [], this.props.selectedMediumTypeObj, this.props.districtList, this.props.selectedDistrict, this.props.selectedConstituency, this.props.constituencyList, this.props.fromDate, this.props.toDate);
				this.setState({clear: !this.state.clear});
			});
		}
		else {
			this.props.onApplyFilter(selectedMediumType, null, null, null, this.props.mediumTypes, media, [], [], this.props.selectedMediumTypeObj, this.props.districtList, this.props.selectedDistrict, this.props.selectedConstituency, this.props.constituencyList, this.props.fromDate, this.props.toDate);
			this.setState({clear: !this.state.clear});
		}
	  }).catch((error) => {
		this.setState({error});
	  })
  }
  
  onChangeMediumType(selectedMediumType, selectedMediumTypeObj) {
	getMedia(selectedMediumType).then((media) => {
	  this.props.onApplyFilter(selectedMediumType, null, null, null, this.props.mediumTypes, (media != undefined ? media : []), [], [], selectedMediumTypeObj, this.props.districtList, this.props.selectedDistrict, this.props.selectedConstituency, this.props.constituencyList, this.props.fromDate, this.props.toDate);
	}).catch((error) => {
	  this.setState({error});
	})
  }

  onChangeMedium(selectedMedia) {
	getTopics(selectedMedia).then((topics) => {
	  let selectedMediumType = this.props.selectedMediumType;
	  this.props.onApplyFilter(selectedMediumType, selectedMedia, null, null, this.props.mediumTypes, this.props.media, topics, [], this.props.selectedMediumTypeObj, this.props.districtList, this.props.selectedDistrict, this.props.selectedConstituency, this.props.constituencyList, this.props.fromDate, this.props.toDate);
	}).catch((error) => {
	  this.setState({error});
	})
  }

  onChangeTopic(selectedTopic) {
	getNewsCategories(selectedTopic).then((newsCategoryList) => {
	  let selectedMediumType = this.props.selectedMediumType;
	  let selectedMedia = this.props.selectedMedia;
	  this.props.onApplyFilter(selectedMediumType, selectedMedia, selectedTopic, null, this.props.mediumTypes, this.props.media, this.props.topics, newsCategoryList, this.props.selectedMediumTypeObj, this.props.districtList, this.props.selectedDistrict, this.props.selectedConstituency, this.props.constituencyList, this.props.fromDate, this.props.toDate);
  }).catch((error) => {
	  this.setState({error});
  })
  }

  onChangeNewsCategory(selectedNewsCategory) {
	let selectedMediumType = this.props.selectedMediumType;
	let selectedMedia = this.props.selectedMedia;
	let selectedTopic = this.props.selectedTopic;
	let newsCategories = this.props.newsCategoryList;
	this.props.onApplyFilter(selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, this.props.mediumTypes, this.props.media, this.props.topics, newsCategories, this.props.selectedMediumTypeObj, this.props.districtList, this.props.selectedDistrict, this.props.selectedConstituency, this.props.constituencyList, this.props.fromDate, this.props.toDate);
  }

  onChangeDistrict(selectedDistrict) {
	let selectedMediumType = this.props.selectedMediumType;
	let selectedMedia = this.props.selectedMedia;
	let selectedTopic = this.props.selectedTopic;
	let selectedNewsCategory = this.props.selectedNewsCategory;
	let newsCategories = this.props.newsCategoryList;
	let districtList = this.props.districtList;
	this.props.onApplyFilter(selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, this.props.mediumTypes, this.props.media, this.props.topics, newsCategories, this.props.selectedMediumTypeObj, districtList, selectedDistrict, null, this.props.constituencyList, this.props.fromDate, this.props.toDate);
  }

  onChangeConstituency(selectedConstituency) {
	let selectedMediumType = this.props.selectedMediumType;
	let selectedMedia = this.props.selectedMedia;
	let selectedTopic = this.props.selectedTopic;
	let selectedNewsCategory = this.props.selectedNewsCategory;
	let newsCategories = this.props.newsCategoryList;
	let districtList = this.props.districtList;
	let selectedDistrict = this.props.selectedDistrict;
	let constituencyList = this.props.constituencyList;
	this.props.onApplyFilter(selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, this.props.mediumTypes, this.props.media, this.props.topics, newsCategories, this.props.selectedMediumTypeObj, districtList, selectedDistrict, selectedConstituency, constituencyList, this.props.fromDate, this.props.toDate);
  }

  onChangeFromDate(event) {
	let selectedMediumType = this.props.selectedMediumType;
	let selectedMedia = this.props.selectedMedia;
	let selectedTopic = this.props.selectedTopic;
	let selectedNewsCategory = this.props.selectedNewsCategory;
	let newsCategories = this.props.newsCategoryList;
	let districtList = this.props.districtList;
	let selectedDistrict = this.props.selectedDistrict;
	let constituencyList = this.props.constituencyList;
	let selectedConstituency = this.props.selectedConstituency;
	let fromDate = event.target.value;
	this.props.onApplyFilter(selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, this.props.mediumTypes, this.props.media, this.props.topics, newsCategories, this.props.selectedMediumTypeObj, districtList, selectedDistrict, selectedConstituency, constituencyList, fromDate, this.props.toDate);
  }

  onChangeToDate(event) {
	let selectedMediumType = this.props.selectedMediumType;
	let selectedMedia = this.props.selectedMedia;
	let selectedTopic = this.props.selectedTopic;
	let selectedNewsCategory = this.props.selectedNewsCategory;
	let newsCategories = this.props.newsCategoryList;
	let districtList = this.props.districtList;
	let selectedDistrict = this.props.selectedDistrict;
	let constituencyList = this.props.constituencyList;
	let selectedConstituency = this.props.selectedConstituency;
	let toDate = event.target.value;
	this.props.onApplyFilter(selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, this.props.mediumTypes, this.props.media, this.props.topics, newsCategories, this.props.selectedMediumTypeObj, districtList, selectedDistrict, selectedConstituency, constituencyList, this.props.fromDate, toDate);
  }

  render() {
	return(
		<>
			<div className='row'>
				<MediumTypes disabled={this.props.mtDisabled} clear={this.state.clear} selectedMediumTypeObj={this.props.selectedMediumTypeObj} mediumTypeFields={newsFilterFields} onChangeMediumType={this.onChangeMediumType} selectedMediumType={this.props.selectedMediumType} mediumTypes={this.props.mediumTypes} />
				<Media disabled={this.props.mediumDisabled} clear={this.state.clear} mediumFields={newsFilterFields} media={this.props.media} selectedMedia={this.props.selectedMedia} onChangeMedium={this.onChangeMedium} selectedMediumType={this.props.selectedMediumType} />
				<Topics clear={this.state.clear} topicFields={newsFilterFields} topics={this.props.topics} onChangeTopic={this.onChangeTopic} selectedTopic={this.props.selectedTopic} selectedMedia={this.props.selectedMedia} selectedMediumType={this.props.selectedMediumType} />
				<NewsCategories newsCategoryList={this.props.newsCategoryList} newsCategoryFields={newsFilterFields} onChangeNewsCategory={this.onChangeNewsCategory} selectedNewsCategory={this.props.selectedNewsCategory} selectedTopic={this.props.selectedTopic} selectedMedia={this.props.selectedMedia} selectedMediumType={this.props.selectedMediumType} />
				{/* <District newsDistrictList={this.props.districtList} newsDistrictFields={newsFilterFields} onChangeNewsDistrict={this.onChangeNewsDistrict} selectedNewsDistrict={this.props.selectedNewsDistrcit} selectedTopic={this.props.selectedTopic} selectedMedia={this.props.selectedMedia} selectedMediumType={this.props.selectedMediumType} /> */}

				<District clear={this.state.clear} districtFields={newsFilterFields} districtList={this.props.districtList} onChangeDistrict={this.onChangeDistrict} selectedDistrict={this.props.selectedDistrict} />
				<Constituency clear={this.state.clear} constituencyFields={newsFilterFields} constituencyList={this.props.constituencyList} onChangeConstituency={this.onChangeConstituency} selectedConstituency={this.props.selectedConstituency} selectedDistrict={this.props.selectedDistrict} />
				<div className="col-md-3 pt-3">
					<div className="form-group">
						<label htmlFor="">From Date</label>
						<input type="date" id='fromdate' className='form-control' value={this.props.fromDate ? this.props.fromDate : ''} onChange={(e) => {this.onChangeFromDate(e)}} />
					</div>
				</div>
				<div className="col-md-3 pt-3">
					<div className="form-group">
						<label htmlFor="">To Date</label>
						<input type="date" id='todate' className='form-control' value={this.props.toDate ? this.props.toDate : ''} onChange={(e) => {this.onChangeToDate(e)}} />
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-md-3 col-lg-3 col-12 my-2'>
					<button className='btn btn-danger ms-1 text-white' onClick={this.handleReset}>Clear</button>
				</div>
			</div>
		</>
	);
  }
}

export default TopicSelection;