import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Dashboard-Style.css';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";

class Media extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      inputValue: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(event) {
    this.props.onChangeMedium(event.id);
    this.setState({id: event.id});
  }

  handleChange(event) {
    this.setState({inputValue: event});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMediumType !== this.props.selectedMediumType || prevProps.clear !== this.props.clear) {
      this.setState({...this.state, inputValue: ''});
    }
  }

  render() {
    const fieldProperties = this.props.mediumFields?.media;
    return(
      <div className={ `col-md-${ fieldProperties?.colmd } col-lg-${ fieldProperties?.collg } col-${ fieldProperties?.col } pt-3` }>
        <div className='form-group text-start my-auto'>
        { fieldProperties?.labelStatus ? <label>{ fieldProperties?.label }</label> : '' }
          <Combobox data={this.props.media} placeholder={fieldProperties?.placeholder} dataKey='id' textField='name' value={ this.props.selectedMedia != null ? this.props.selectedMedia : this.state.inputValue } onChange={this.handleChange} onSelect={this.handleSelect} disabled={this.props.disabled ? true :false}/>
        </div>
      </div>
    );
  }
}

export default Media;