import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

ChartJS.register(ArcElement, Tooltip, Legend);

class DashboardDoughnut extends React.Component {
  render() {
    return(
      <div className='col-md-4 col-sm-6 col-lg-4 col-xl-4'>
        <div className='card mt-2 border border-1 rounded-3 shadow'>
          <div className='card-body doughnut-style'>
            <div className='fs-5 fw-600'>
                <p>{this.props.loading == false  && this.props.data?.datasets[0].label}</p>
            </div>
            {
              this.props.loading == false ? 
              <div>
                <div className='text-start fw-regular'>
                </div>
                <div className='w-100'>
                <Pie data={this.props.data} />
                </div>
              </div> : 
              this.props.loading == true ? 
              <div className='data-loader text-center'>
                 <RotatingLines strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true} />
              </div> : 
              this.props.error != null ?
              <Error /> : <div className='text-center fw-600 fs-6 text-black-50'>No data found</div>
              
            }
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardDoughnut;