import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect } from 'react';
import {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/css/style.css';
import Footer from "components/Footer";
import Layout from "./components/Layout";
import Dashboard from "./components/dashboard/Dashboard";
import Voters from './components/voter/Voters';
import VoterSummary from "components/voter/VoterSummary";
import HouseIncharges from './components/housesincharges/HouseIncharges';
import UpdateVoterDetails from './components/voter/UpdateVoterDetails';
import VoterDetailsView from "./components/voter/VoterDetailsView";
import AddIncharge from './components/housesincharges/AddIncharges';
import PollingStationIncharges from './components/dashboard/PollingStationIncharges';
import HouseVisitedCount from './components/dashboard/HouseVisitedCount';
import CommentsOfVolunteer from "./components/dashboard/CommentsofVolunteer";
import ImportantVoters from "./components/dashboard/ImportantVoters";
import Houses from './components/houses/Houses';
import Elections from './components/elections/Elections';
import NewVolunteer from "./components/elections/NewVolunteer";
import ViewIncharges from './components/housesincharges/ViewIncharge';
import VillageProfile from './components/VillageProfile/VillageProfile';
import VillageProfileList from './components/VillageProfile/VillageProfileList';
import VillageProfileView from './components/VillageProfile/VillageProfileView';
import VillageProfileUpdate from './components/VillageProfile/VillageProfileUpdate';
import VoterSurvey from './components/VoterSurvey/VoterSurvey';
import VoterSurveyList from './components/VoterSurvey/VoterSurveyList';
import VoterSurveyView from './components/VoterSurvey/VoterSurveyView';
import VoterSurveyResponses from './components/VoterSurvey/VoterSurveyResponses';
import VoterSurveySubmit from './components/VoterSurvey/VoterSurveySubmit';
import VolunteerComments from "./components/VolunteerComments/VolunteerComments";
import AddPollingStationIncharge from './components/PollingStationIncharge/AddNewPollingStationIncharge';
import AddNewHouseIncharge from "./components/houses/AddNewHouseIncharge";
import SmsVoters from "./components/Sms/SmsVoters";
import SmsVoterDetailsView from './components/Sms/SmsVoterDetailsView';
import SmsUpdateVoterDetails from './components/Sms/SmsUpdateVoterDetails';
import SmsTemplate from './components/Sms/SmsTemplate';
import ViewBirthdayAlert from './components/birthdayAlerts/ViewBirthdayAlert';
import Login from './components/account/Login';
import AccessDenied from './components/AccessDenied';
import Settings from './components/Settings/Settings';
import { Navigate } from 'react-router-dom';
import NotFound from './components/NotFound';
import { getAccessToken } from "axios-jwt";
import UserProfile from './components/account/UserProfile';
import ChangePassword from './components/account/ChangePassword';
import PasswordReset from './components/account/PasswordReset';
import PasswordRestConfirm from './components/account/PasswordRestConfirm';
import { axiosInstance, logout } from './services/Auth';
import {Operations} from './components/toast/operations';
import CacheSettings from "./components/Settings/CacheSettings/CacheSettings";
import HousesListTable from "./components/houses/HousesListTable";
import HouseView from "./components/houses/HouseView";
import EventCalendar from "./components/Calendar/EventCalendar";
import UserAssignEntity from "./components/elections/UserAssignEntity";
import { getUserProfile, getGlobelSettings } from './services/Voters';
import { Roles } from "./config/defaults";
import RotatingLinesLoader from "./components/RotatingLineLoader";
import UserAssignView from './components/elections/UserAssignView';
import DigitalLetter from "components/DigitalLetter";
import TagsListing from "./components/TagsListing";
import DashboardFloatingFeed from './components/dashboard/DashboardFloatingFeed';

const userRole = 'MLA';
function App() {

  const ADMIN = Roles.SUPER_ADMIN.value;
  const AC = Roles.AC_INCHARGE.value;
  const BLOCK = Roles.BLOCK_INCHARGE.value;
  const PS = Roles.POLLING_STATION_INCHARGE.value;
  const HOUSE = Roles.HOUSE_INCHARGE.value;


  axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if(error.message.includes('Got 401')){
        logout();
        onLogout();
    }
    throw error;
  });
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserLoaded, setUserLoaded] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const [globelSettings, setGlobelSettings] = useState(null);
  const onLogin = () => {
    setIsLoggedIn(true);
    getUserProfile().then((userProfile) => {
      setUserProfile(userProfile);
      setUserLoaded(true);
    }).catch((error) => {
      setError(error);
    })

    getGlobelSettings().then((globelSettings) => {
      setGlobelSettings(globelSettings);
    }).catch((error) => {
      setError(error);
    })
  };

  const onLogout = () => {
    setIsLoggedIn(false);
    setUserProfile(null);
  };

  useEffect(() => {
    const token = getAccessToken();
    if(token) setIsLoggedIn(true);
    
    getUserProfile().then((userProfile) => {
      setUserProfile(userProfile);
      setUserLoaded(true);
    }).catch((error) => {
      setError(error);
    })
  }, []);

  const token = getAccessToken();

  function validatePermissions(roles, route) {
      return route;
  }
  return (
    <div className="App">
      <div className="wrapper">
        {!token && (
          <BrowserRouter>
            <Routes>
              <Route path='*' element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login onLogin={onLogin} onLogout={onLogout}/>} />
              <Route path="reset" element={ <PasswordReset /> } />
              <Route path="reset-password" element={<PasswordRestConfirm onLogout={onLogout} /> } />
            </Routes>
          </BrowserRouter>
        )}
        {isUserLoaded && token && (
          <BrowserRouter>
            <Layout onLogout={onLogout} globelSettings={globelSettings} />
            <div className="content-wrapper floating-feed-container">
              <div className='container-fluid py-4 px-4'>
                <Routes>
                  <Route path='*' element={<NotFound />} />
                  {/* Redirect login route to home page*/}
                  {
                    userProfile?.user?.role == ADMIN || userProfile?.user?.role == AC || userProfile?.user?.role == BLOCK || userProfile?.user?.role == PS? 
                      <Route path='/login' element={<Navigate to="/" />}/> : 
                    userProfile?.user?.role == Roles.HOUSE_INCHARGE.value ? 
                      <Route path='/' element={<Navigate to="/houses-list-table" />}/> : <Route path='/login' element={<Navigate to="/" />}/>
                  }
                  <Route path="/" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard key={'root'}/>)} />
                  <Route path="dashboard" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard />)} />
                  <Route path="chief-minister" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard tagGroup={14} key={'chief-minister'}/>)} />
                  <Route path="cg-govt" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard />)} />
                  <Route path="party" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard />)} />
                  <Route path="bjp-mlas" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard />)} />
                  <Route path="inc-mlas" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard />)} />
                  <Route path="dist-admin" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard />)} />
                  <Route path="dashboard" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard />)} />
                  <Route path="dashboard" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Dashboard />)} />
                  <Route path="voters" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Voters />)} />
                  <Route path="voter-summary" element={validatePermissions([ADMIN], <VoterSummary />)} />
                  <Route path="house-incharges" element={validatePermissions([ADMIN], <HouseIncharges />)} />
                  <Route path="digital-letter/:path/:voterid" element={validatePermissions([ADMIN, HOUSE, AC, PS, BLOCK], <DigitalLetter />)} />
                  <Route path="update-voter-details/:path/:voterid" element={validatePermissions([ADMIN, HOUSE, AC, PS, BLOCK], <UpdateVoterDetails />)} />
                  <Route path="voter-details-view/:path/:voterid" element={validatePermissions([ADMIN, HOUSE, AC, PS, BLOCK], <VoterDetailsView role={userProfile?.user?.role} />)} />
                  <Route path="add-incharge" element={validatePermissions([ADMIN], <AddIncharge />)} />
                  <Route path="pollingStation-incharges" element={validatePermissions([ADMIN], <PollingStationIncharges />)} />
                  <Route path="house-visited-count" element={validatePermissions([ADMIN], <HouseVisitedCount />)} />
                  <Route path="comments-of-volunteer" element={validatePermissions([ADMIN, AC, BLOCK, PS], <CommentsOfVolunteer />)} />
                  <Route path="important-voters" element={validatePermissions([ADMIN, AC, BLOCK, PS], <ImportantVoters />)} />
                  <Route path="houses" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Houses />)} />
                  <Route path="elections" element={validatePermissions([ADMIN, AC, BLOCK, PS], <Elections />) } />
                  <Route path="user-profile" element={ validatePermissions([ADMIN, AC, PS, BLOCK, HOUSE], <UserProfile onLogout={onLogout}/>) } />
                  <Route path="change-password" element={ validatePermissions([ADMIN, AC, PS, BLOCK, HOUSE], <ChangePassword onLogout={onLogout}/>) } />
                  <Route path="new-volunteer" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <NewVolunteer />) } />
                  <Route path="view-incharge" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <ViewIncharges />) } />
                  <Route path="village-profile" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VillageProfile />) } />
                  <Route path="village-profile-list" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VillageProfileList />) } />
                  <Route path="village-profile-view/:id" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VillageProfileView />) } />
                  <Route path="village-profile-update/:id" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VillageProfileUpdate isUpdate={true}/>) } />
                  <Route path="voter-survey" element={ validatePermissions([ADMIN, AC], <VoterSurvey />) } />
                  <Route path="voter-survey-list" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VoterSurveyList role={userProfile?.user?.role} />) } />
                  <Route path="voter-survey-view/:id" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VoterSurveyView />) } />
                  <Route path="voter-survey-responses/:id" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VoterSurveyResponses />) } />
                  <Route path="voter-survey-submit/:id" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VoterSurveySubmit />) } />
                  <Route path="volunteer-comments" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <VolunteerComments />) } />
                  <Route path="add-new-pollingStation-incharge" element={ validatePermissions([ADMIN], <AddPollingStationIncharge />) } />
                  <Route path="add-new-house-incharge" element={ validatePermissions([ADMIN], <AddNewHouseIncharge />) } />
                  <Route path="sms-voters" element={ validatePermissions([ADMIN], <SmsVoters />) } />
                  <Route path="sms-voter-details-view" element={ validatePermissions([ADMIN], <SmsVoterDetailsView />) } />
                  <Route path="sms-update-voter-details" element={ validatePermissions([ADMIN], <SmsUpdateVoterDetails />) } />
                  <Route path="sms-template" element={ validatePermissions([ADMIN], <SmsTemplate />) } />
                  <Route path="view-birthday-alert" element={validatePermissions([ADMIN], <ViewBirthdayAlert group='birthday-alerts' key='birthday-alerts' />) } />
                  <Route path="birthday-alert-settings" element={ validatePermissions([ADMIN], <Settings group='birthday-settings' key='birthday-settings' />) } />
                  <Route path="houses-list-table" element={ validatePermissions([HOUSE], <HousesListTable />) } />
                  <Route path="house-view/:path/:id" element={ validatePermissions([ADMIN, AC, BLOCK, PS, HOUSE], <HouseView role={userProfile?.user?.role} />) } />
                  <Route path="settings" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <Settings group='global' key='global' path='settings' operations={Operations.UPDATE_SETTINGS} />) } />
                  <Route path="sms-settings" element={ validatePermissions([ADMIN], <Settings group='sms-settings' key='sms-settings' path='sms-settings' operations={Operations.UPDATE_SMS_SETTINGS} />) } />
                  <Route path="cache-settings" element={ validatePermissions([ADMIN], <CacheSettings group='cache-settings' key='cache-settings' path='cache-settings' />) } />
                  <Route path="event-calendar" element={ validatePermissions([ADMIN], <EventCalendar />) } />
                  <Route path="user-assign/:id" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <UserAssignEntity />) } />
                  <Route path="user-assign-view/:id" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <UserAssignView />) } />
                  <Route path="tags-listing" element={ validatePermissions([ADMIN, AC, BLOCK, PS], <TagsListing />) } />
                  <Route path="/not-found" component={ <NotFound /> } />
                </Routes>
                <DashboardFloatingFeed />
              </div>
            </div>
            <Footer />
          </BrowserRouter>
        )}
        {!isUserLoaded && token && (
          <RotatingLinesLoader />
        )}
      </div>
    </div>
  );
}

export default App;
