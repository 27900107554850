import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Voters.css';
import { Link } from 'react-router-dom';
import { getVoterData } from '../services/Voters';
import { useParams } from "react-router-dom";
import RotatingLinesLoader from './RotatingLineLoader';
import Error from './error';
import { getGenderName, getRelationTypeName } from '../services/Voters';
import Candidate from 'assets/img/candidate.jpg';
import Madhav from 'assets/img/madhav.jpg';
import BJPLogo from 'assets/img/bjp_lotus.png';
import BJPLogo300 from 'assets/img/bjp_lotus_300.png';
import ModiTran from 'assets/img/modi-tran.png';
import ModiTran300 from 'assets/img/modi-tran_300.png';

function DigitalLetter() {
  const [voterData, setVoterData] = useState(null);
  const [error, setErr] = useState(null);
  const { voterid } = useParams();
  const { path } = useParams();
  const [loaderStatus, setLoaderStatus] = useState(false);

  useEffect(() => {
    setLoaderStatus(true);
    setTimeout(() => {
      getVoterData(voterid).then((voterData) => {
        setVoterData(voterData);
        setLoaderStatus(false);
      }).catch((error) => {
        setErr(error);
        setLoaderStatus(false);
      })
    }, 3000);
  }, []);

    return(
        <div>
        <div className="container-fluid top-banner d-flex ps-0" style={{minHeight: '18vh', backgroundColor: '#f47216'}}>
        <div className="bg-success" style={{height: '18vh', width: '5vh'}}></div>
        <div className="p-2" style={{height: '18vh', width: '18vh'}}>
            <img src={BJPLogo300} alt="" className="img-responsive" width="100%"/>
        </div>
        <h1 className="my-auto mx-auto  text-white" style={{fontFamily: "'Ramabhadra', sans-serif", fontSize: '4rem'}}>మాధవ్ గారికి ఓటు, అభివృద్ధికి ఓటు!</h1>
        <div className="p-3 ms-auto" style={{height: '18vh', width: '18vh'}}>
            <img src={ModiTran300} alt="" className="img-responsive" width="100%"/>
        </div>
    </div>
    <div className="container-fluid">
        <div className="row flex-column-reverse flex-md-row p-5">
            <div className="col-12 col-md-7 p-2">
                <p className="fs-5">Pokala Vamsi Nagendra Madhav (P.V.N. Madhav) is a Member of the Legislative Council(MLC) from Srikakulam, Vizianagaram, Visakhapatnam constituencies in Andhra Pradesh. He was born on 10-08-1973 to P. Chalapathi Rao and P. Radha Chalapathi in Maddilapalem, Visakhapatnam.</p>
                <p className="fs-5">He completed SSC from Vijnana Vihar, Gudilova, and Intermediate from Shri Krishna Vidya Mandir, he went on to complete AICWAI (Cost Accountant) while doing B.Com from Dr. V.S.Krishna Collage. He completed Post Graduate in MBA from Andhra University Campus, while simultaneously completing PGDCS and PGDAS. Along with education.</p>
                <p className="fs-5">He started his Political Journey with the Rashtriya Swayamsevak Sangh (RSS), followed by a tenure as City Secretary, State Executive Member, and State Joint Secretary for Akhila Bharata Vidyarthi Parishad (ABVP). He also participated in diverse social and political activities. He joined Swadeshi Jagaran Manch as a full-time worker and worked across India. In Mumbai, he worked at the Rambhau Malgi Prabodhini (RMP) to analyze “Role of Foreign Institutional Investors FII’s in Indian Stock Market and its impact” and published his findings as a book.</p>
            </div>
            <div className="col-12 col-md-5">
                <img src={Madhav} alt="" width="100%" className="img-fluid rounded mx-auto d-block img-thumbnail image-hover-effect"/>
            </div>
        </div>
        <div className="row">
            <div className="col-12 p-5">
                <div className="ratio ratio-4x3 video-embed">
                    <iframe className="rounded" src="https://www.youtube.com/embed/V5IvAaLE-Ko" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        </div>
        <div className="row pb-2">
            <div className="col-12 px-5">
                <table className="table table-striped border table-hover">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{voterData?.first_name ? voterData?.first_name : ''} {voterData?.last_name ? voterData?.last_name : ''}</td>
                        </tr>
                        <tr>
                            <th>Voter Id</th>
                            <td>{voterData?.id_card_number ? voterData?.id_card_number : ''}</td>
                        </tr>
                        <tr>
                            <th>Age</th>
                            <td>{voterData?.age ? voterData?.age : ''}</td>
                        </tr>
                        <tr>
                            <th>Village</th>
                            <td>{voterData?.polling_station.village ? voterData.polling_station?.village.name : ''}</td>
                        </tr>
                        <tr>
                            <th>Constituency</th>
                            <td>{voterData?.polling_station.constituency ? voterData.polling_station.constituency?.name : ''}</td>
                        </tr>
                        <tr>
                            <th>House No</th>
                            <td>{voterData?.house ? voterData?.house?.house_no : ''}{voterData?.house ? `${voterData?.house?.society != null ? ', ' + voterData?.house?.society : '' }` : ''}</td>
                        </tr>
                        <tr>
                            <th>State</th>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js" integrity="sha384-w76AqPfDkMBDXo30jS1Sgez6pr3x5MlQ1ZAGC+nuZB+EYdgRZgiwxhTBTkF7CXvN" crossOrigin="anonymous"></script>
    </div>
    );
}

export default DigitalLetter;