import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Dashboard-Style.css';
import "react-widgets/styles.css";
import { TagsByNewsListingGet, TagListGet, mediaURL } from '../services/Voters';
import Pagination from './Pagination';
import { closestIndexTo } from "date-fns/fp";

class TagsListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loading: false,
            pageSize: 9,
            pageNUmber: 1,
            currentTag: '',
            tagList: []
        };
        this.handleTags = this.handleTags.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.handleTagsClick = this.handleTagsClick.bind(this);
    }

    onChangePage(event) {
		this.handleTags(event, this.state.pageSize, this.state.currentTag);
	}

    handleTagsClick(event) {
        if(event != '') {
            this.handleTags(this.state.pageNUmber, this.state.pageSize, event);
        }
    }

    handleTags(pageNUmber, pageSize, tag) {
        TagsByNewsListingGet(pageNUmber, pageSize, tag).then((list) => {
			this.setState({list, loading: false, pageNUmber, pageSize, currentPage: tag});
		}).catch((error) => {
			this.setState({error});
		})
    }

    componentDidMount() {
        TagsByNewsListingGet(this.state.pageNUmber, this.state.pageSize, '').then((list) => {
            this.setState({list, loading: false});
        }).catch((error) => {
            this.setState({error});
        })

        TagListGet().then((tagList) => {
            this.setState({tagList});
        }).catch((error) => {
			this.setState({error});
		})
	}

    render() {
        return(
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                            {
                                this.state.list?.data?.map((value, index) => 
                                    <div className="col-md-4 col-12" key={index}>
                                        <div className="card">
                                            <div className="card-body tag-list-card-background-style p-0 overflow-hidden">
                                                <img src={`${mediaURL}${value?.image}`} alt="" />
                                            </div>
                                            <div className="card-footer">
                                                <h3>{value?.title}</h3>
                                                <div className="row">
                                                    <div className="col-6">
                                                        {value?.category['name']}
                                                    </div>
                                                    <div className="col-6">
                                                        {value?.type['name']}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {value?.topics?.map((topicValue, index) =>
                                                            <span key={index}>{index > 0 ? `, ${topicValue?.name}` : topicValue?.name }</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            </div>
                            <div className="text-center">
                                {
                                    this.state.list?.count >= this.state.pageSize ? 
                                    <Pagination pageCount={this.state.list?.total_pages} currentPage={this.state.list?.current_page - 1} pageSize={this.state.pageSize} onChangePage={this.onChangePage} /> : ''
                                }
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body">
                                    {
                                        this.state.tagList?.map((tagValue, index) => 
                                            <span className="badge rounded-pill bg-primary m-1 p-2 tag-button" key={index} onClick={(event) => {this.handleTagsClick(tagValue?.name)}} >{tagValue?.name}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default TagsListing;