import React from 'react';
import '../assets/css/Sidebar-Style.css';

class Footer extends React.Component {
  render() {
    return(
      <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
          <b>Version</b> 1.0.0
        </div>
        <strong>Copyright © 2022 <a href="https://cultnerds.io">CG Pulse</a>.</strong> All rights reserved.
      </footer>
    );
  }
}

export default Footer;