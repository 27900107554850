import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterCircleXmark, faFilter, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import TopicSelection from './TopicSelection';
import { getMedia, getTopics, getNewsCategories, getAllMediumTypes, getNewsTypeWiseChartData, getDistrictWiseChartData, getNewsTypes, getConstituencyWiseChartData, getMediumTypeWiseChartData, getDistrictWiseNewsItemCounts, getConstituencyWiseNewsItemCounts, getDistricts, getConstituencyList } from '../../services/Voters';
import {generateRandomDarkColor} from '../helper/Validations';
import DashboardDoughnut from '../dashboard/DashboardDoughnut';
import DashboardColumnChart from '../dashboard/DashboardColumnChart';
import DashBoardConstituencyChart from '../dashboard/DashboardConstituencyChart';

function sumArray(arr) { 
	let sum = 0; 
	for (let i = 0; i < arr?.length; i++) 
		sum += arr[i]; 
  
	return sum; 
  }

function getNewsTypeChart(newsTypeChartData) {
	let allNewsTypes = {name: 'Sentiment', labels: newsTypeChartData?.labels?.map((label, index) => `${label} (${newsTypeChartData?.counts[index]})`), datasets: [{label: 'Sentiment Analysis', data: newsTypeChartData?.counts, backgroundColor: newsTypeChartData?.colors, borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],borderWidth: 3,},],}
	return allNewsTypes;
  }

function getDistrictWiseChart(data) {

	let dData = {};
	if(data != undefined) {
		for(let i=0;i<data.length;i++) {
			let dObj = dData[data[i].name];
			if(dObj == undefined) {
				dObj = {};
			}
			
			dObj[data[i].sentiment] = data[i].count;
			dData[data[i].name] = dObj;
		}
	}


	let option = {
		series: [{
              name: 'Positive',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Positive"] || 0;})
            }, {
              name: 'Neutral',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Neutral"] || 0;})
            }, {
              name: 'Negative',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Negative"] || 0;})
            }],
			zoom: {
        enabled: true,
        type: 'x',
        resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F'
        },
        selection: {
            background: '#90CAF9',
            border: '#0D47A1'
        }    
    },
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
				stackType: '100%'
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    total: {
                    //   enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              title: {
                text: 'Sentiment Analysis'
              },
			  colors: ['#8FC93A', '#F9C846', '#D81E5B'],
              xaxis: {
                categories: Object.keys(dData),
                labels: {
                  formatter: function (val) {
                    return val //+ "K"
                  }
                }
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val //+ "K"
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            }
		};

	return option;
}

function getDistrictWiseNewsCounts(data) {
	console.log(JSON.stringify(data));

	let dData = {};
	if(data != undefined) {
		for(let i=0;i<data.length;i++) {
			dData[data[i].name] = data[i].count;
		}
	}


	let option = {
		series: [{
              name: 'News Item Counts',
              data: Object.keys(dData).map((dist) => {return dData[dist];})
            }],
			zoom: {
        enabled: true,
        type: 'x',
        resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F'
        },
        selection: {
            background: '#90CAF9',
            border: '#0D47A1'
        }    
    },
            options: {
              chart: {
                type: 'bar',
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    total: {
                    //   enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              title: {
                text: 'News Item Counts'
              },
			  colors: ['#8FC93A', '#F9C846', '#D81E5B'],
              xaxis: {
                categories: Object.keys(dData),
                labels: {
                  formatter: function (val) {
                    return val //+ "K"
                  }
                }
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val //+ "K"
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            }
		};

	return option;
}

function getConstituencyWiseNewsCounts(data) {

	let dData = {};
	if(data != undefined) {
		for(let i=0;i<data.length;i++) {
			dData[data[i].name] = data[i].count;
		}
	}


	let option = {
		series: [{
              name: 'News Item Counts',
              data: Object.keys(dData).map((constituency) => {return dData[constituency];})
            }],
			zoom: {
        enabled: true,
        type: 'x',
        resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F'
        },
        selection: {
            background: '#90CAF9',
            border: '#0D47A1'
        }    
    },
            options: {
              chart: {
                type: 'bar',
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    total: {
                    //   enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              title: {
                text: 'News Item Counts'
              },
			  colors: ['#8FC93A', '#F9C846', '#D81E5B'],
              xaxis: {
                categories: Object.keys(dData),
                labels: {
                  formatter: function (val) {
                    return val //+ "K"
                  }
                }
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val //+ "K"
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            }
		};

	return option;
}

function getMediumTypeWiseChart(data) {
	let dData = {};
	if(data != undefined) {
		for(let i=0;i<data.length;i++) {
			let dObj = dData[data[i].name];
			if(dObj == undefined) {
				dObj = {};
			}
			
			dObj[data[i].sentiment] = data[i].count;
			dData[data[i].name] = dObj;
		}
	}

	let option = {
		series: [{
              name: 'Positive',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Positive"] || 0;})
            }, {
              name: 'Neutral',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Neutral"] || 0;})
            }, {
              name: 'Negative',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Negative"] || 0;})
            }],
            options: {
              chart: {
                type: 'bar',
                height: 150,
                stacked: true,
				stackType: '100%',
				stackType: '100%'
              },
              plotOptions: {
                bar: {
                //   horizontal: true,
                  dataLabels: {
                    total: {
                    //   enabled: true,
                      offsetX: 0,
					  offsetY: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              title: {
                text: 'Sentiment Analysis'
              },
			  colors: ['#8FC93A', '#F9C846', '#D81E5B'],
              xaxis: {
                categories: Object.keys(dData),
                labels: {
                  formatter: function (val) {
                    return val //+ "K"
                  }
                }
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val //+ "K"
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            }
		};

	return option;
}

function getConstituencyWiseChart(data) {

	let dData = {};
	if(data != undefined) {
		for(let i=0;i<data.length;i++) {
			let dObj = dData[data[i].name];
			if(dObj == undefined) {
				dObj = {};
			}
			
			dObj[data[i].sentiment] = data[i].count;
			dData[data[i].name] = dObj;
		}
	}

	let option = {
		series: [{
              name: 'Positive',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Positive"] || 0;})
            }, {
              name: 'Neutral',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Neutral"] || 0;})
            }, {
              name: 'Negative',
              data: Object.keys(dData).map((dist) => {let obj = dData[dist]; return obj["Negative"] || 0;})
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
				stackType: '100%'
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    total: {
                    //   enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              title: {
                text: 'Sentiment Analysis'
              },
			  colors: ['#8FC93A', '#F9C846', '#D81E5B'],
              xaxis: {
                categories: Object.keys(dData),
                labels: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            }
	};

return option;
}

class Dashboard extends React.Component {
  
  constructor(props) {
	super(props);
	this.state = {
	  value: '',
	  cards: [],
	  filterShowing: true,
	  selectedMediumType: 1,
	  selectedMedia: null,
	  selectedTopic: null,
	  selectedNewsCategory: null,
    selectedDistrict: null,
    selectedConstituency: null,
    fromDate: null,
    toDate: null,
	  mediumTypes: [],
	  media: [],
      topics: [],
      newsCategoryList: [],
      districtList: [],
      constituencyList: [],
	  initialPage: '1',
	  selectedMediumTypeObj: null,
	  mtDisabled: false,
	  mediumDisabled: false,
	  pageNumber: 1,
	  pageSize: 10,
	  newsTypeWiseData: {},
	  mediumTypeWiseData: {},
	  constituencyWiseData: {},
	  districtWiseData: {},
	  districtWiseNewsItemCounts: {},
	  constituencyWiseNewsItemCounts: {},
	  newsTypeDoughNutError: '',
	  newsTypeDoughNutLoaderStatus: true,
	  newsTypesData: null,
      tagGroup: ''
	};

	this.handleChange = this.handleChange.bind(this);
	this.onApplyFilter = this.onApplyFilter.bind(this);
	//this.onClickCard = this.onClickCard.bind(this);
  }

//   onClickCard(selectedId) {
// 	if(this.state.selectedVillage != null && (this.state.blocks?.length == 0 || this.state.selectedBlock != null)) {
// 		this.onApplyFilter(this.state.selectedConstituency, this.state.selectedBlock, this.state.selectedVillage, selectedId, this.state.mediumTypes, this.state.blocks, this.state.villages, this.state.newsCategoryList, this.state.selectedConstituencyObj);
// 	}
// 	else if(this.state.selectedVillage == null && (this.state.blocks?.length == 0 || this.state.selectedBlock != null)) {
// 		getNewsCategories(selectedId).then((newsCategoryList) => {
// 			this.onApplyFilter(this.state.selectedConstituency, this.state.selectedBlock, selectedId, this.state.selectedPollingStation, this.state.mediumTypes, this.state.blocks, this.state.villages, newsCategoryList, this.state.selectedConstituencyObj)
// 		}).catch((error) => {
// 			this.setState({error});
// 		})
// 	}
// 	else if(this.state.selectedMedia == null && this.state.selectedMediumType != null && this.state.media.length != 0) {
// 		getTopics(selectedId).then((villages) => {
// 			this.onApplyFilter(this.state.selectedMediumType, selectedId, this.state.selectedVillage, this.state.selectedPollingStation, this.state.mediumTypes, this.state.blocks, villages, this.state.pollingStationList, this.state.selectedConstituencyObj)
// 		}).catch((error) => {
// 		this.setState({error});
// 		})
// 	}
	
//   }

  handleChange(event) {
	this.setState({value: event.target.value, filterShowing: !this.state.filterShowing});
  }

  onApplyFilter(selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, mediumTypes, media, topics, newsCategoryList, selectedMediumTypeObj, districtList, selectedDistrict, selectedConstituency, constituencyList, fromDate, toDate) {

	let event = [];
	let mediumDisabled = false;
	if (media == undefined || media.length == 0) {
		mediumDisabled = true;
	}
	if(newsCategoryList != undefined && newsCategoryList.length != 0) {
	  event = newsCategoryList;
	}
	// else if(topics != undefined && topics.length != 0) {
	//   event = topics;
	// }
	else if (media != undefined && media.length != 0) {
	  event = media;
	}

	let newsTypeWiseData = null;
	let newsTypeDoughNutLoaderStatus = true;
	let newsTypeDoughNutError = '';

	getNewsTypeWiseChartData(this.props.tagGroup, selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, selectedDistrict, selectedConstituency, fromDate, toDate).then((chartData) => {
		let labels = chartData.map(item => item.name);
		let counts = chartData.map(item => item.count);
		let colors = chartData.map(item => item.color);
		newsTypeWiseData = {labels, counts, colors};
		newsTypeDoughNutLoaderStatus = false;
		if(Object.keys(chartData).length != 0)
			newsTypeDoughNutError = null;
		else {
			console.log("Error");
			newsTypeDoughNutError = '';
		}
		this.setState({newsTypeWiseData, newsTypeDoughNutLoaderStatus, newsTypeDoughNutError});
		
	}).catch((err) => {
		newsTypeDoughNutError = err;
		newsTypeDoughNutLoaderStatus = false;
		this.setState({newsTypeWiseData, newsTypeDoughNutLoaderStatus, newsTypeDoughNutError});
	});

	getMediumTypeWiseChartData(selectedMediumType, selectedDistrict, selectedConstituency, fromDate, toDate).then((mediumTypeWiseData) => {
		this.setState({mediumTypeWiseData});
	}).catch((err) => {
		this.setState({error: err});
	});

	getDistrictWiseChartData(this.props.tagGroup, selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, selectedDistrict, fromDate, toDate).then((districtWiseData) => {
		this.setState({districtWiseData});
	}).catch((err) => {
		this.setState({error: err});
	});

	getConstituencyWiseChartData(this.props.tagGroup, selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, selectedDistrict, selectedConstituency, fromDate, toDate).then((constituencyWiseData) => {
		this.setState({constituencyWiseData});
	}).catch((err) => {
		this.setState({error: err});
	});

	if(selectedDistrict != null) {
		getConstituencyList(selectedDistrict).then((constituencyList) => {
			this.setState({constituencyList});
		  }).catch((err) => {
			this.setState({err});
		  });
	}

	getDistrictWiseNewsItemCounts(this.props.tagGroup, selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, selectedDistrict, fromDate, toDate).then((districtWiseNewsItemCounts) => {
		this.setState({districtWiseNewsItemCounts});
	}).catch((err) => {
		this.setState({error: err});
	});

	getConstituencyWiseNewsItemCounts(this.props.tagGroup, selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, selectedDistrict, selectedConstituency, fromDate, toDate).then((constituencyWiseNewsItemCounts) => {
		this.setState({constituencyWiseNewsItemCounts});
	}).catch((err) => {
		this.setState({error: err});
	});

	this.setState({
		cards: event.map((data) => ({...data, color: generateRandomDarkColor()})),
		selectedMediumType,
		selectedMedia,
		selectedTopic,
		selectedNewsCategory,
		mediumTypes,
		media,
		// topics,
		// newsCategoryList,
		selectedMediumTypeObj,
		voterCountLoader: true,
		voterCount: null,
		houseLoaderStatus: true,
		houses: null,
		importantVotersLoaderStatus: true,
		mediaWiseData: {},
		mediumDisabled,
		newsTypeWiseData,
		newsTypeDoughNutLoaderStatus,
		newsTypeDoughNutError,
    selectedDistrict,
    districtList,
    constituencyList,
    selectedConstituency,
    fromDate,
    toDate
	});
  }

  componentDidMount() {
    this.setState({tagGroup: this.props.tagGroup});
	getNewsTypes().then((newsTypesData) => {
		this.setState({newsTypesData});
	}).catch((err) => {
		this.setState({error: err});
	})

	getDistrictWiseChartData(this.props.tagGroup, null, null, null, null, null, null).then((districtWiseData) => {
		this.setState({districtWiseData});
	}).catch((err) => {
		this.setState({error: err});
	});

	getConstituencyWiseChartData(this.props.tagGroup, null, null, null, null, null, null).then((constituencyWiseData) => {
		this.setState({constituencyWiseData});
	}).catch((err) => {
		this.setState({error: err});
	});

	getDistrictWiseNewsItemCounts(this.props.tagGroup).then((districtWiseNewsItemCounts) => {
		this.setState({districtWiseNewsItemCounts});
	}).catch((err) => {
		this.setState({error: err});
	});

	getConstituencyWiseNewsItemCounts(this.props.tagGroup).then((constituencyWiseNewsItemCounts) => {
		this.setState({constituencyWiseNewsItemCounts});
	}).catch((err) => {
		this.setState({error: err});
	});

	getMediumTypeWiseChartData('').then((mediumTypeWiseData) => {
		this.setState({mediumTypeWiseData});
	}).catch((err) => {
		this.setState({error: err});
	});

	this.setState({newsTypeWiseData: {}, newsTypeDoughNutLoaderStatus: true});
	getNewsTypeWiseChartData(this.props.tagGroup, null, null, null, null).then((chartData) => {
		let labels = chartData.map(item => item.name);
		let counts = chartData.map(item => item.count);
		let colors = chartData.map(item => item.color);
		let newsTypeWiseData = {labels, counts, colors};
		this.setState({ newsTypeWiseData, newsTypeDoughNutLoaderStatus: false});
		}).catch((err) => {
			this.setState({newsTypeDoughNutError: err, newsTypeDoughNutLoaderStatus: false});
		});
		

	getAllMediumTypes().then((mediumTypes) => {
		let selectedMediumType = null;
  
		if(mediumTypes && mediumTypes.length == 0)
			this.setState({mtDisabled: true});
  
		getMedia(selectedMediumType).then((media) => {
  
		  let newsCategoryList = [];
		  let selectedNewsCategory = null;
		  let selectedTopic = null;
		  let selectedMedia = null;
  
		  let event = [];
		  if (media != undefined && media.length != 0) {
			  event = media;
		  }
		  
		  this.setState({cards: event.map((data) => ({...data, color: generateRandomDarkColor()})), selectedMediumType, selectedMedia, selectedTopic, selectedNewsCategory, mediumTypes, media});
		}).catch((error) => {
		  this.setState({error});
		})
	  }).catch((err) => {
		// alert("Server error, please try again later.");
	  });

	  getTopics(null).then((topics) => {
		this.setState((prevState, props) => ({...prevState, topics}));
	  });

	  getNewsCategories(null).then((newsCategoryList) => {
		this.setState((prevState, props) => ({...prevState, newsCategoryList}));
	  });

    getDistricts().then((districtList) => {
      this.setState({districtList});
    }).catch((err) => {
      this.setState({err});
    });

  }

  render() {
	let newsTypeData = getNewsTypeChart(this.state.newsTypeWiseData);
	let districtWiseData = getDistrictWiseChart(this.state.districtWiseData);
	let mediumTypeWiseData = getMediumTypeWiseChart(this.state.mediumTypeWiseData);
	let constituencyWiseChart = getConstituencyWiseChart(this.state.constituencyWiseData);
	let districtWiseNews = getDistrictWiseNewsCounts(this.state.districtWiseNewsItemCounts);
	let constituencyWiseNews = getConstituencyWiseNewsCounts(this.state.constituencyWiseNewsItemCounts);
	return(
		<>
			<div className='row'>
			  <div className='col-md-12 text-start p-3'>
			  Dashboard <span className='bg-success p-1 text-white rounded-3' onClick={this.handleChange}><FontAwesomeIcon icon={this.state.filterShowing ? faFilterCircleXmark: faFilter} /></span>
			  </div>
			</div>
			<div className='row'>
			{this.state.filterShowing && <TopicSelection mtDisabled={this.state.mtDisabled} mediumDisabled={this.state.mediumDisabled} onApplyFilter={this.onApplyFilter} selectedMediumType={this.state.selectedMediumType} selectedMedia={this.state.selectedMedia} selectedTopic={this.state.selectedTopic} selectedNewsCategory={this.state.selectedNewsCategory} mediumTypes={this.state.mediumTypes} media={this.state.media} topics={this.state.topics} newsCategoryList={this.state.newsCategoryList} selectedMediumTypeObj={this.state.selectedMediumTypeObj} districtList={this.state.districtList} constituencyList={this.state.constituencyList} selectedDistrict={this.state.selectedDistrict} selectedConstituency={this.state.selectedConstituency} fromDate={this.state.fromDate} toDate={this.state.toDate} />}
			<DashboardDoughnut data={newsTypeData} count={sumArray(this.state.newsTypeWiseData?.counts)} chartData={this.state.newsTypeWiseData} error={this.state.newsTypeDoughNutError} loading={this.state.newsTypeDoughNutLoaderStatus} />
			<div className='col-md-8 col-sm-12 col-lg-8 col-xl-8'>
                <div className='card mt-2 border border-1 rounded-3 shadow hp-95'>
                    <div className='card-body doughnut-style'>
                        <div className='fs-5 fw-600'>
						<DashBoardConstituencyChart title={'Media Wise'} horizontalBarData={mediumTypeWiseData} />
						</div>
                    </div>
                </div>
            </div>
			<div className='col-md-12 col-sm-12 col-lg-12 col-xl-12'>
                <div className='card mt-2 border border-1 rounded-3 shadow hp-95'>
                    <div className='card-body doughnut-style'>
                        <div className='fs-5 fw-600'>
						<DashBoardConstituencyChart title={'District Wise'} horizontalBarData={districtWiseData} />
						</div>
                    </div>
                </div>
            </div>
			<div className='col-md-12 col-sm-12 col-lg-12 col-xl-12'>
                <div className='card mt-2 border border-1 rounded-3 shadow hp-95'>
                    <div className='card-body doughnut-style'>
                        <div className='fs-5 fw-600'>
							<DashBoardConstituencyChart title={'Constituency Wise'} horizontalBarData={constituencyWiseChart} />
						</div>
                    </div>
                </div>
            </div>
			<div className='col-md-12 col-sm-12 col-lg-12 col-xl-12'>
                <div className='card mt-2 border border-1 rounded-3 shadow hp-95'>
                    <div className='card-body doughnut-style'>
                        <div className='fs-5 fw-600'>
						<DashBoardConstituencyChart title={'District Wise'} horizontalBarData={districtWiseNews} />
						</div>
                    </div>
                </div>
            </div>
			<div className='col-md-12 col-sm-12 col-lg-12 col-xl-12'>
                <div className='card mt-2 border border-1 rounded-3 shadow hp-95'>
                    <div className='card-body doughnut-style'>
                        <div className='fs-5 fw-600'>
							<DashBoardConstituencyChart title={'Constituency Wise'} horizontalBarData={constituencyWiseNews} />
						</div>
                    </div>
                </div>
            </div>
			</div>
		</>
	);
  }
}

export default Dashboard;