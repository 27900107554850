import React from "react";
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";

class MediumTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      inputValue: this.props.selectedMediumTypeObj ? this.props.selectedMediumTypeObj : { "id": null, "name": "" }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(event) {
    this.props.onChangeMediumType(event.id, event);
  }

  handleChange(event) {
    this.setState({inputValue: event});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMediumType !== this.props.selectedMediumType) {
      this.setState({inputValue: { "id": 1, "name": "Print" }, ...this.state});
    }
    if(prevProps.clear !== this.props.clear) {
      this.setState({...this.state, inputValue: null});
      this.props.onChangeMediumType(null, null);
    }
  }
  
  render() {
    const fieldProperties = this.props.mediumTypeFields.mediumType;
    return(
      <div className={ `col-md-${fieldProperties?.colmd} col-lg-${fieldProperties?.collg} col-${fieldProperties?.col} col-sm-${fieldProperties?.collg} pt-3` }>
        <div className='form-group text-start my-auto'>
          { fieldProperties.labelStatus ? <label>{ fieldProperties.label }</label> : '' }
          <Combobox data={this.props.mediumTypes} options={this.props.mediumTypes} placeholder={fieldProperties?.placeholder} dataKey='id' textField='name' value={ this.state.inputValue } onChange={this.handleChange} onSelect={this.handleSelect} disabled={this.props.disabled}/>
        </div>
      </div>
    );
  }
}

export default MediumTypes;