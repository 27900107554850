import {React, useEffect, useState} from 'react';
import '../assets/css/Sidebar-Style.css';
import  OnlyOne  from './OnlyOne'; 
import Brand from '../assets/img/brand.png';
import usericon from '../assets/img/cg-pulse-logo.jpeg';
import { BRANDING_TITLE } from '../config/defaults';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, useLocation } from "react-router-dom";
import CustomLink from './CustomLink';

function checkIfActive(menu, route) {
  if(menu.children?.length == 0)
    return menu.url == route;
  else {
    for(let i=0;i<menu.children?.length;i++) {
      let active = checkIfActive(menu.children[i], route);
      if(active)
        return true;
    }
    return false;
  }
}


function Sidebar(props) {

  var route = useLocation()?.pathname;
	const [isActive,setIsActive] = useState(false);

	let active = checkIfActive(props, route);

  const handleParentBtnClick = () =>{
		setIsActive(!isActive);
	}
console.log(route);
    return(
       <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/" className="brand-link text-decoration-none">
          <img src={usericon} alt="Brand Logo" className="brand-image img-circle elevation-3 rounded-circle border border-white" style={{opacity: .8}} />
          {/* <span className="brand-text font-weight-light fw-600">{props.globelSettings != null ? props.globelSettings : BRANDING_TITLE}</span> */}
        </a>
        <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-transition os-host-overflow-x">
          <div className="os-resize-observer-host observed">
            <div className="os-resize-observer resize-observer-style">
            </div>
          </div>
          <div className="os-size-auto-observer observed size-auto-observer-style">
            <div className="os-resize-observer">
            </div>
          </div>
          <div className="os-content-glue content-glue-style">
          </div>
          <div className="os-padding">
            <div className="os-viewport os-viewport-native-scrollbars-invisible os-viewport-native-scrollbars-invisible-polls">
              <div className="os-content os-content-style">

              <div className="sidenav">
                  <OnlyOne menu={props.tabs} />
                  <div className='main-menu'>
                  <CustomLink link={`/tags-listing`} classes={route == `/tags-listing` ? 'single-nav-link align-middle active' : 'single-nav-link align-middle'} text={`News Items`} key={`news-items`} />
                  </div>
              </div>
              </div>
            </div>
          </div>
        <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle os-scrollbar-handle-polls"></div></div></div><div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle os-scrollbar-handle-polls-corner"></div></div></div><div className="os-scrollbar-corner"></div></div>
      </aside>
  );

}

export default Sidebar;
