import React from 'react';
import { TagsByNewsListingGet, mediaURL } from '../../services/Voters';

function dateFormat(oldDate) {
    let date = new Date(oldDate);
    let newDate = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
    return newDate
}

class DashboardFloatingFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            floatingFeedData: {}
        };
    }

    componentDidMount() {
        TagsByNewsListingGet(null, null, null).then((data) => {
            this.setState({floatingFeedData: data});
        }).catch((err) => {
            this.setState({error: err});
        });
    }

    render() {
        return (
            <>
                <div className="modal fade" id="latesttagfloatingfeed" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">News Item Feed</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            {
                                this.state.floatingFeedData ? this.state.floatingFeedData?.data?.map((value, index) =>
                                    <div className="card" key={index}>
                                    <div className="card-body p-0">
                                        <img src={`${mediaURL}${value?.image}`} alt="" className='floating-feed-image' />
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-6">{value?.category?.name}</div>
                                            <div className="col-6 text-right">{dateFormat(value?.updated_on)}</div>
                                        </div>
                                    </div>
                                </div>
                                ) : 'No data found'
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DashboardFloatingFeed;