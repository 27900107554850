import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Dashboard-Style.css';
import "react-widgets/styles.css";
import { Combobox } from "react-widgets/cjs";


class Constituency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: '',
      id: '',
      inputValue: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(event) {
    this.props.onChangeConstituency(event.id);
    this.setState({id: event.id});
  }

  handleChange(event) {
    this.setState({inputValue: event});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDistrict !== this.props.selectedDistrict || prevProps.clear !== this.props.clear) {
      this.setState({...this.state, inputValue: ''});
    }
  }

  render() {
    const fieldProperties = this.props.constituencyFields?.constituency;
    return(
        <div className={ `col-md-${ fieldProperties.colmd } col-lg-${ fieldProperties.collg } col-${ fieldProperties.col } pt-3` }>
        <div className="form-group my-auto">
            { fieldProperties.labelStatus ? <label>{ fieldProperties.label }</label> : '' }
          <Combobox data={this.props.constituencyList} placeholder={ fieldProperties.placeholder } dataKey='id' textField='name' value={ this.props.selectedConstituency != null ? this.props.selectedConstituency : this.state.inputValue } onChange={ this.handleChange } onSelect={this.handleSelect} />
        </div>
      </div>
    )
  }
}

export default Constituency;